import React, { useEffect, useState } from "react";
import { useIntersection } from "react-use";

import * as animationLogoIntro from "./coignet_anim_logo.json"
import * as animationLogoOO from "./OO_anim_2.json"
import * as animationLogoOOBeige from "./OO_anim_beige.json"

import Lottie from 'react-lottie';

export const IntersectionContext = React.createContext({ inView: true });

export const LottieWrapper = ({
  isReset,
  children,
  reset = isReset || false,
  anim,
  delay,
  thresholdParam
}) => {
  const [inView, setInView] = useState(false);

  const intersectionRef = React.useRef(null);

  const [isStopped, setIsStopped] = useState(true);

  const AnimLogoIntro = {
    loop: false,
    autoplay: false,
    animationData: animationLogoIntro.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimLogoOO = {
    loop: false,
    autoplay: false,
    animationData: animationLogoOO.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

    const AnimLogoOOBeige = {
    loop: false,
    autoplay: false,
    animationData: animationLogoOOBeige.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const intersection = useIntersection(intersectionRef, {
    threshold: thresholdParam || 0.6
  });

  const [WindowScope, setWindowScope] = useState();

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > (thresholdParam || 0.59);
    const windowGlobal = typeof window !== 'undefined' && window;
    setWindowScope(windowGlobal);

    if (inViewNow) {
      windowGlobal.setTimeout(() => setIsStopped(false), delay || 0);
    } else if (reset) {
      return setIsStopped(true);
    }
  }, [intersection, reset, thresholdParam, WindowScope]);

  let WindowScopeValue = WindowScope;

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={intersectionRef} className=" pointer-events-none">
        {anim === "LogoIntro" &&
          <Lottie options={AnimLogoIntro}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
        {anim === "LogoOO" &&
          <Lottie options={AnimLogoOO}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
        {anim === "LogoOOBeige" &&
          <Lottie options={AnimLogoOOBeige}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
      </div>
    </IntersectionContext.Provider>
  );
};