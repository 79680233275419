
import React from 'react';
import LogoGreyEdmond from "../svg/logo_grey";
import LogoTactile from "../svg/logo_tactile";
import Obfuscate from 'react-obfuscate';
import { IntersectionObserver } from "../intersection/intersection";
import { AppearAnimText } from "../animations/text_fade";
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { Link } from "gatsby"

const paragraphAppear = {
    initial: {
      opacity: 0,
      y: 70
    },
    hidden: {
      opacity: 0,
      y: 70,
      transition: {
        duration: 2.5,
      }
    },
    show: {
      opacity: 1, y: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 20,
        mass: 0.5,
      }
    },
  }

export const Footer = () => (
    <>
      <motion.section key="footer" layout id="section-contact" className="background-blue-edmond z-30 w-full relative flex-col items-start justify-start">
        <IntersectionObserver isReset={true}  >
          <AppearAnimText>
            <div className="flex flex-row items-center justify-center w-full">
              <div className="w-full flex items-center justify-between gap-10 desktop:gap-20 flex-col desktop:flex-row background-gray-edmond p-10 desktop:p-20">
                <h1 className="text-center desktop:text-left uppercase sofia-semibold text-6xl desktop:text-5xl stroke-white">Contact</h1>
                <div className="items-center w-full justify-center gap-10 desktop:gap-20 flex flex-col desktop:flex-row">
                <motion.div variants={paragraphAppear} className="flex flex-col p-5 text-center desktop:text-left">
                  <p className="uppercase text-base xs:text-lg tracking-wider text-white sofia-bold">Téléphone</p>
                  <p className="uppercase text-xs xs:text-sm mt-1 tracking-wider text-white sofia-regular">
                    <Obfuscate tel="01 47 20 47 00" />
                  </p>
                </motion.div>
                <motion.div variants={paragraphAppear} className="flex flex-col p-5 text-center desktop:text-left">
                  <p className="uppercase text-base xs:text-lg tracking-wider text-white sofia-bold">Email</p>
                  <p className="uppercase text-xs xs:text-sm mt-1 tracking-wider text-white sofia-regular">
                    <Obfuscate
                      email="contact@coignet.fr" />
                  </p>
                </motion.div>
                <motion.div variants={paragraphAppear} className="flex flex-col p-5 text-center desktop:text-left">
                  <p className="uppercase text-base xs:text-lg tracking-wider text-white sofia-bold">Adresse</p>
                  <p className="uppercase text-xs xs:text-sm mt-1 tracking-wider text-white sofia-regular">75 rue de courcelles<br></br>75008 Paris</p>
                </motion.div>
                </div>
              </div>
            </div>
          </AppearAnimText>
        </IntersectionObserver>
        <div className="flex items-center pt-20 desktop:pt-40 pb-20 justify-center w-full">
          <div className="flex flex-col items-center justify-center">
            <div className="w-56 xs:w-70"><LogoGreyEdmond /></div>
            <p className="text-center tracking-wide text-menu mt-10 desktop:mt-20 sofia-regular text-darkblue-edmond uppercase"><Link to="/mentions">Crédits/mentions légales</Link></p>
            <p className="text-center tracking-wide text-menu mt-5 sofia-regular text-darkblue-edmond uppercase">© 2020 Coignet Investment All rights reserved.</p>
            <div className="flex flex-row items-center justify-center gap-2 text-center tracking-wide text-menu mt-10 sofia-regular text-darkblue-edmond uppercase"><p>designed by</p>
              <a href="https://www.agence-tactile.fr" aria-label="Site Tactile" rel="noreferrer noopener" target="_blank" className="cursor-pointer"><LogoTactile className="w-16" style={{ fill: '#5B6B74' }} /></a>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  )