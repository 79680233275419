import React, { useMemo, useContext } from "react";
import { IntersectionContext } from "../intersection/intersection";
import { motion } from "framer-motion";

export const AppearAnimText = ({
  children,
  delay,
  delayOrder = delay, // order of appearance
  duration = 0.3,
  easing = "linear" // [number, number, number, number] | "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | EasingFunction;
}) => {
  const { inView } = useContext(IntersectionContext);
  const transition = useMemo(
    () => ({
      duration,
      delay: delayOrder,
      ease: easing,
      staggerChildren: 0.4
    }),
    [duration, delayOrder, easing]
  );

  const variants = {
    hidden: {
      opacity: 0,
      y:0,
      duration: 1.5,
    },
    show: {
      opacity: 1,
      y:0,
      transition: {
        duration: 0.5,
        delay: delay,
        staggerChildren: 0.15,
      },
    },
    exit: {
      opacity: 0,
      y:0,
      duration: 1.5,
    },
  };

  return (
    <motion.div
      initial="initial"
      animate={inView ? "show" : "hidden"}
      exit="exit"
      variants={variants}
    >
      {children}
    </motion.div>
  );
};