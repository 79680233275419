import * as React from "react"

function LogoGreyEdmond(props) {
  return (
    <svg
      x={0}
      y={0}
      viewBox="0 0 1026.1 209.8"
      {...props}
    >
      <style>{".prefix__st0{fill:#5a6c76}"}</style>
      <path
        className="prefix__st0"
        d="M372.5 6h24v117.3h-24zM544.8 83.4c-.2 1.2-.6 2.2-1 3.1-3.6 10.8-14.8 17.2-30 17.2-19.9 0-36.1-17.5-36.1-39.1 0-21.4 15.8-38.2 36.1-38.2 10 0 19.2 4 26.1 11.4L557.6 23C547 11.2 531.1 4.4 513.8 4.4c-33.8 0-60.3 26.5-60.3 60.3 0 16.1 6.2 31.1 17.4 42.4 11.3 11.4 26.5 17.7 42.9 17.7 36.5 0 56.9-22.1 57.5-62.2h-60.5v18.8h34.3l-.3 2zM100.4 42l18.5-12.9C107.7 13.6 89.7 4.4 70.5 4.4c-32.9 0-59.6 26.9-59.6 60 0 33.2 26.7 60.3 59.6 60.3 19.2 0 37.2-9.1 48.4-24.5l-18.5-13.1c-7 10-16.7 15.1-28.8 15.1-20.8 0-36.4-16.3-36.4-37.8 0-21.3 15.7-37.3 36.6-37.3 11.8 0 21.6 5.1 28.6 14.9zM729.3 123.3h-23.7v-20.5l-.3-.4c-17.5-28.5-36.3-51.3-56.1-67.6l-2.8-2.3v90.8h-23.7V5.6h24.9c19.9 14.1 37.3 32.2 54.8 57l3.1 4.4V5.6h23.7v117.7z"
      />
      <path className="prefix__st0" d="M396.5 123.3h-24V6h24v117.3z" />
      <path
        className="prefix__st0"
        d="M372.5 6h24v117.3h-24zM867.7 123.3h-75.6V6h74v20.6h-49.9v28.3H860v21.2h-43.8v26.6h51.5v20.6zM1015.2 26.5h-31.9v96.7h-24.1V26.5h-31.9V6h87.8l.1 20.5zM313.1 64.5c0 16.1-6.2 31.3-17.4 42.6-11.3 11.4-26.4 17.7-42.6 17.7-2 0-4.1-.1-6.1-.3 23.2-8.4 39.6-29.6 41.5-55 .1-1.8.2-3.5.2-5s-.1-3.1-.2-5c-2.2-19.5-16.3-32.6-35.2-32.6-5.2 0-10.1 1-14.6 2.9l-.1.1-1.2.5c-.7.4-1.4.7-2.1 1.1-11 6.3-17.8 18.7-17.8 33 0 14.2 6.6 26.5 17.6 33-.1 0-.2.1-.4.1-.5.2-1.1.3-1.6.4-.2.1-.4.1-.5.1-.7.2-1.4.3-2.1.4-.1 0-.3 0-.4.1-.6.1-1.2.1-1.8.2-.2 0-.4 0-.6.1-.7.1-1.5.1-2.2.1-1.1 0-2.1-.1-3.1-.1-.5-.1-1.1-.1-1.6-.2-14.5-2.1-25.5-13.6-27.3-29.4-.1-1.8-.2-3.2-.2-4.7s.1-2.9.2-4.7c2-26.4 20.6-48 46.1-53.9 4.4-1 8.9-1.5 13.4-1.5 16.2 0 31.3 6.2 42.6 17.6 11.3 11.2 17.5 26.3 17.5 42.4z"
      />
      <path
        className="prefix__st0"
        d="M285.3 64.5c0 1.4-.1 3-.2 4.7-2 26.4-20.6 48.2-46.1 54-4.5 1-9 1.5-13.6 1.5-32.9 0-59.8-27-59.8-60.3 0-33.1 26.8-60.1 59.8-60.1 2.1 0 4.2.1 6.3.3h-.1c-.1.1-.3.1-.4.2-.6.2-1.2.4-1.8.7-.3.1-.5.2-.8.3-.5.2-.9.4-1.4.6-.3.1-.6.3-.9.4-.4.2-.9.4-1.3.6-.3.2-.6.3-.9.5l-1.2.6c-.3.2-.6.3-.9.5-.4.2-.8.4-1.2.7l-.9.6c-.4.2-.8.5-1.1.7l-.9.6c-.4.3-.7.5-1.1.8-.3.2-.6.4-.8.6-.4.3-.8.6-1.1.9-.3.2-.5.4-.8.6-.4.4-.9.7-1.2 1-.2.2-.4.3-.6.5l-1.8 1.5-.6.6-1.1 1.1-.7.7c-.3.3-.6.6-.9 1-.2.3-.5.5-.7.8-.3.3-.6.6-.9 1-.2.3-.5.5-.7.8-.3.3-.5.7-.8 1-.2.3-.4.6-.7.9-.3.3-.5.7-.8 1l-.6.9c-.2.4-.5.7-.7 1.1l-.6.9c-.3.4-.5.8-.7 1.1l-.6.9c-.2.4-.5.8-.7 1.2-.1.3-.3.5-.5.8-.3.5-.6 1.1-.9 1.6-.1.2-.2.3-.3.5-.4.7-.7 1.4-1 2.1-.1.3-.2.5-.4.8-.2.5-.4.9-.6 1.4-.1.3-.2.6-.4.9-.2.4-.4.9-.5 1.3-.1.3-.2.6-.4 1-.1.4-.3.9-.4 1.3-.1.3-.2.7-.3 1-.1.4-.3.9-.4 1.3-.1.3-.2.7-.3 1-.1.4-.2.9-.4 1.3-.1.3-.2.7-.3 1-.1.5-.2.9-.3 1.4-.1.3-.1.7-.2 1l-.3 1.5c0 .3-.1.7-.2 1-.1.5-.1 1.1-.2 1.6 0 .3-.1.6-.1.8-.1.8-.2 1.7-.2 2.5-.1 1.9-.2 3.4-.2 4.9 0 1.5.1 3.1.2 5 .3 2.4.7 4.7 1.4 7 4.5 15.5 17.2 25.9 34 25.8 5.2 0 10.1-1 14.6-3 1.1-.5 2.2-1.1 3.3-1.7 10.9-6.4 17.6-18.7 17.6-33.1 0-14.2-6.6-26.4-17.3-32.8 3-.9 6.2-1.3 9.6-1.3 17 0 29.8 11.9 31.8 29.5.1 1.7.2 3.2.2 4.6z"
      />
      <g>
        <path
          className="prefix__st0"
          d="M15.4 172.6h6.3V204h-6.3v-31.4zM50.2 172.7h6c4.8 4 8.6 8.9 12.2 15v-15h6v31.4h-6v-5.6c-3.9-7.5-8-13.3-12.2-17.4v23h-6v-31.4zM120.7 172.7l-9.6 31.4h-5.7L96 172.7h6.6l5.8 21.2 5.8-21.2h6.5zM166.5 198.6v5.6h-20.1v-31.4h19.7v5.6h-13.4v7.5h11.7v5.7h-11.7v7h13.8zM192.4 195h6.3c0 2.3 2.2 4.1 5.1 4.2 2.8 0 4.7-1.4 4.7-3.7 0-1.7-1.3-3-4-3.8l-3.8-1.1c-6.9-1.8-8.1-6-8.1-8.8 0-5.6 4.9-9.4 10.9-9.3 5.9 0 10.5 3.6 10.5 9.6h-6.3c0-2.2-1.6-4-4.3-4-2.5 0-4.3 1.5-4.3 3.6 0 .8.2 2.4 3.5 3.3l3.5 1c4.7 1.3 8.9 3.8 8.9 9.2 0 6.2-5.4 9.5-11.3 9.5-6.8 0-11.3-4.1-11.3-9.7zM262.7 178.5H254v25.8h-6.3v-25.8H239v-5.6h23.7v5.6zM317.3 204.4l-2.3-19.5-7.7 15.9h-5l-7.7-15.9-2.3 19.5h-6.5l3.9-31.4h5.4l9.7 20.6 9.6-20.6h5.5l3.8 31.4h-6.4zM371.5 198.9v5.6h-20.1v-31.4h19.7v5.6h-13.4v7.5h11.7v5.7h-11.7v7h13.8zM398.7 173.1h6c4.8 4 8.6 8.9 12.2 15v-15h6v31.4h-6v-5.6c-3.9-7.5-8-13.3-12.2-17.4v23h-6v-31.4zM468.7 178.8H460v25.8h-6.3v-25.8H445v-5.6h23.7v5.6z"
        />
        <g>
          <path
            className="prefix__st0"
            d="M551.6 204.7l-2.3-19.5-7.7 15.9h-5l-7.7-15.9-2.3 19.5h-6.5l3.9-31.4h5.4l9.7 20.6 9.7-20.6h5.5l3.8 31.4h-6.5zM609.1 204.8H603c-.3-2.1-.7-4.2-1.2-6.5-3.8.1-8.4 1-12.2 2.6-.3 1.3-.5 2.6-.6 3.8h-6.1c1.5-10.5 5.1-20.9 10.4-31.4h5.3c5.6 10.5 9.1 21 10.5 31.5zm-18-10c3-1.1 6.3-1.8 9.3-2-1.2-4-2.6-8-4.4-11.8-2 4.4-3.6 9.1-4.9 13.8zM631.3 173.4h6c4.8 4 8.6 8.9 12.2 15v-15h6v31.4h-6v-5.6c-3.9-7.5-8-13.3-12.2-17.4v23h-6v-31.4zM703.7 204.9h-6.1c-.3-2.1-.7-4.2-1.2-6.5-3.8.1-8.4 1-12.2 2.6-.3 1.3-.5 2.6-.6 3.8h-6.1c1.5-10.5 5.1-20.9 10.4-31.4h5.3c5.5 10.5 9 21 10.5 31.5zm-18-10c3-1.1 6.3-1.8 9.3-2-1.2-4-2.6-8-4.4-11.8-2 4.4-3.7 9.2-4.9 13.8zM723.8 189.2c0-8.9 7-16.1 16.1-16.1 4.8 0 8.9 2 11.6 5l-4.6 4c-1.8-1.9-4.3-3.1-7-3.1-5.5 0-9.7 4.6-9.7 10.3 0 5.6 4.2 10.5 9.7 10.5 4.1 0 7.3-1.9 8.2-5.2.1-.2.1-.5.2-.8h-8.9v-5.2h15.8c0 11.6-6.3 16.8-15.3 16.8-9.1-.1-16.1-7.3-16.1-16.2zM801.9 199.4v5.6h-20.1v-31.4h19.7v5.6h-13.4v7.5h11.7v5.7h-11.7v7h13.8zM859.4 205.1l-2.3-19.5-7.7 15.9h-5l-7.7-15.9-2.3 19.5h-6.5l3.9-31.4h5.4l9.7 20.6 9.7-20.6h5.5l3.8 31.4h-6.5zM913.6 199.5v5.6h-20.1v-31.4h19.7v5.6h-13.4v7.5h11.7v5.7h-11.7v7h13.8zM940.8 173.8h6c4.8 4 8.6 8.9 12.2 15v-15h6v31.4h-6v-5.6c-3.9-7.5-8-13.3-12.2-17.4v23h-6v-31.4zM1010.8 179.4h-8.7v25.8h-6.3v-25.8h-8.7v-5.6h23.7v5.6z"
          />
        </g>
      </g>
    </svg>
  )
}

export default LogoGreyEdmond